import React, { memo } from 'react';
import { SplashScreen } from '../splash-screen';
import { ReactComponent as FlashIcon } from '../../assets/icons/warning.svg';

export const CameraAccessMessage = memo((props) => {
  return (
    <div className="App">
      <div className="container">
        <SplashScreen
          hideButton
          text={<>Для отображения контента необходимо разрешение для камеры</>}
          icon={<FlashIcon style={{ width: 64, height: 64 }} />}
        />
      </div>
    </div>
  );
});
