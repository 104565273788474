import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './app.css';
import Base from './components/base';

export const GlobalContext = createContext({
  reloadApp: () => {},
});

const RELOAD_TIMEOUT = 10 * 60 * 1000;

function App() {
  // const [id, setId] = useState(crypto.randomUUID());
  const reloadPage = useCallback(() => {
    /*setId(crypto.randomUUID());*/
    window.location.reload();
  }, []);
  const contextValue = useMemo(() => ({ reloadApp: reloadPage }), [reloadPage]);

  useEffect(() => {
    const createReloadTimeout = () =>
      setTimeout(() => {
        reloadPage();
      }, RELOAD_TIMEOUT);
    let reloadTimeout = createReloadTimeout();

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        clearTimeout(reloadTimeout);
      } else {
        reloadPage();
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <GlobalContext.Provider value={contextValue}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Base />} />
          <Route path=":id" element={<Base />} />
        </Routes>
      </BrowserRouter>
    </GlobalContext.Provider>
  );
}

export default App;
