import { useState, useEffect } from 'react';

export const useWebxrAvailable = () => {
  const [supported, setSupported] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    if (navigator?.xr) {
      navigator?.xr
        .isSessionSupported('immersive-ar')
        .then((supported) => setSupported(supported))
        .catch((e) => setSupported(false));
    } else {
      setSupported(false);
    }
  }, []);

  return {
    supported,
    loading,
  };
};
