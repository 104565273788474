import React, { useEffect, useRef, useState } from 'react';
import PhotoMode from './photo-mode';
import { PhotoScreen } from './photo-screen';
import { CameraAccessMessage } from './camera-access-message';
import { useMindarError } from './use-mindar-error';

function MindARModel({ data }) {
  const [photoUrl, setPhotoUrl] = useState(null);
  const sceneRef = useRef(null);
  const sceneWrapperRef = useRef(null);
  const hasMindArError = useMindarError(sceneRef?.current);

  useEffect(() => {
    const sceneEl = sceneRef.current;
    const arSystem = sceneEl.systems['mindar-image-system'];
    sceneEl.addEventListener('renderstart', () => {
      arSystem.start(); // start AR
    });

    // grab the entity reference
    const el = document.querySelector('a-entity');
    // pause - run at 0 speed
    el.setAttribute('animation-mixer', { timeScale: 0 });
    // play - run at normal speed(1)
    el.setAttribute('animation-mixer', { timeScale: 1 });

    return () => {
      arSystem.stop();
    };
  }, []);

  function take_photo() {
    const video =
      sceneWrapperRef.current.children[
        sceneWrapperRef.current.children.length - 1
      ];
    const canvas = document.createElement('canvas');
    video.pause();

    const style = window.getComputedStyle(video),
      width = parseFloat(style.getPropertyValue('width')),
      height = parseFloat(style.getPropertyValue('height')),
      top = parseFloat(style.getPropertyValue('top')),
      left = parseFloat(style.getPropertyValue('left'));

    const imgLeft = (left * video.videoWidth) / width;
    const imgTop = (top * video.videoHeight) / height;
    const drawLeft = imgLeft > 0 ? 0 : imgLeft;
    const drawTop = imgTop > 0 ? 0 : imgTop;
    const drawWidth = video.videoWidth;
    const drawHeight = video.videoHeight;
    canvas.width = video.videoWidth + imgLeft * 2;
    canvas.height = video.videoHeight + imgTop * 2;

    const imgDataElement = document
      .querySelector('a-scene')
      .components.screenshot.getCanvas('perspective');
    const ctx = canvas.getContext('2d');

    ctx.drawImage(video, drawLeft, drawTop, drawWidth, drawHeight);
    ctx.drawImage(imgDataElement, 0, 0, canvas.width, canvas.height);

    const url = canvas.toDataURL('image/png');
    setPhotoUrl(url);
  }

  if (photoUrl !== null)
    return <PhotoScreen url={photoUrl} setUrl={setPhotoUrl} />;

  return (
    <>
      {hasMindArError && <CameraAccessMessage />}
      <div
        ref={sceneWrapperRef}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <a-scene
          ref={sceneRef}
          mindar-image={`imageTargetSrc:${data?.trigger?.filePath};uiScanning: no;filterMinCF:0.0001; filterBeta: 0.001`}
          color-space="sRGB"
          embedded
          autoStart="false"
          renderer="colorManagement: true"
          vr-mode-ui="enabled: false"
          device-orientation-permission-ui="enabled: false"
          height="100%"
          width="100%"
          isMobile="true"
          loading-screen="dotsColor: #ffffff; backgroundColor: #2d2d2d"
        >
          <a-assets>
            <a-asset-item
              id="avatarModel"
              response-type="arraybuffer"
              src={data?.filePath}
            />
          </a-assets>

          <a-camera
            fov="80"
            position="0 0 0"
            look-controls="enabled: false"
            height="100%"
            width="100%"
          />

          <a-entity mindar-image-target="targetIndex: 0">
            <a-gltf-model
              src="#avatarModel"
              rotation="0 0 0"
              animation-mixer="loop: once"
              position={`${data?.offsetX !== null ? data?.offsetX : 0} ${
                data?.offsetY !== null ? data?.offsetY : 0
              } ${data?.offsetZ !== null ? data?.offsetZ : 0}`}
              scale={`${data?.scale !== null ? data?.scale : 1} ${
                data?.scale !== null ? data?.scale : 1
              } ${data?.scale !== null ? data?.scale : 1}`}
            />
          </a-entity>
        </a-scene>
        <PhotoMode take_photo={take_photo} />
      </div>
    </>
  );
}

export default MindARModel;
