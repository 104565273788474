import * as THREE from 'three';
import React, { useEffect } from 'react';

const vertexShader = document.getElementById('vertexShader').textContent;
const fragmentShader = document.getElementById('fragmentShader').textContent;

export function Video(props) {
  const { position, scale, quaternion, videoRef, isAlphaChannel } = props;

  const scaleUpd = new THREE.Vector3(scale.x * 10, scale.y * 10, scale.z * 10);

  // const texture = useLoader(THREE.VideoTexture, videoRef.current);
  const texture = new THREE.VideoTexture(videoRef.current);

  const w = (videoRef.current.videoWidth / videoRef.current.videoHeight) * 10;
  const h = 10;

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      videoRef.current.pause();
    };
    // eslint-disable-next-line
  }, [videoRef.current]);

  useEffect(() => {
    if (texture) {
      texture.minFilter = THREE.LinearFilter;
      texture.magFilter = THREE.LinearFilter;
    }
    //eslint-disable-next-line
  }, [isAlphaChannel]);

  return (
    <mesh position={position} scale={scaleUpd} quaternion={quaternion}>
      <planeBufferGeometry attach="geometry" args={[w, h]} />
      {!isAlphaChannel && (
        <meshBasicMaterial
          attach="material"
          map={texture}
          side={THREE.FrontSide}
          toneMapped={false}
        />
      )}
      {isAlphaChannel && (
        <shaderMaterial
          transparent
          uniforms={{
            map: { value: texture },
            keyColor: { value: [0.0, 1.0, 0.0] },
            similarity: { value: 0.74 },
            smoothness: { value: 0.0 },
          }}
          vertexShader={vertexShader}
          fragmentShader={fragmentShader}
        />
      )}
    </mesh>
  );
}
