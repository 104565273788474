import { useGLTF } from '@react-three/drei';
import React from 'react';

export const Reticle = (props) => {
  const { position, scale, quaternion, refObj } = props;
  const gltf = useGLTF('/reticle.glb');

  return (
    <mesh
      ref={refObj}
      position={position}
      scale={scale}
      quaternion={quaternion}
      castShadow
      receiveShadow
      name="reticle"
    >
      <primitive object={gltf.scene} />
    </mesh>
  );
};
