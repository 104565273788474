import React, { useEffect, useRef, useState } from 'react';
import PhotoMode from './photo-mode';
import { PhotoScreen } from './photo-screen';
import { useMindarError } from './use-mindar-error';
import { CameraAccessMessage } from './camera-access-message';

const MindARVideoAlfa = ({ data }) => {
  const sceneWrapperRef = useRef(null);
  const sceneRef = useRef(null);
  const [firstView, setFirstView] = useState(true);
  const [haveOne, setHaveOne] = useState(false);
  const [haveClick, setClick] = useState(false);
  const [procent, setProcent] = useState(1);

  const [photoUrl, setPhotoUrl] = useState(null);
  const [lastPhotoUrl, setLastPhotoUrl] = useState(null);
  const hasMindArError = useMindarError(sceneRef?.current);

  async function getMediaURLForTrack(texture_to_update, passed_url) {
    await fetch(passed_url, { method: 'GET' }).then((response) => {
      texture_to_update.src = response.url;
    });
  }
  useEffect(() => {
    const sceneEl = sceneRef.current;
    const arSystem = sceneEl.systems['mindar-image-system'];
    sceneEl.addEventListener('renderstart', () => {
      arSystem.start(); // start AR
    });

    var video = document.getElementById('Bunny');
    video.addEventListener(
      'loadedmetadata',
      function (e) {
        var width = this.videoWidth,
          height = this.videoHeight;
        console.log('width', width);
        console.log('height', height);
        setProcent(height / width);
      },
      false
    );
    video.crossOrigin = 'anonimous';

    getMediaURLForTrack(video, data?.filePath);

    return () => {
      arSystem.stop();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const video = document.getElementById('Bunny');
    const exampleTarget = document.querySelector('#example-target');

    const handleTargetFound = () => {
      setFirstView(false);
      if (haveClick) {
        video.play();
      }
    };

    const handleTargetLost = () => {
      setFirstView(true);
      video.pause();
    };

    exampleTarget.addEventListener('targetFound', handleTargetFound);
    exampleTarget.addEventListener('targetLost', handleTargetLost);

    const handleWindowClick = (event) => {
      if (!event.target.closest('#Bunny') && !haveClick && !firstView) {
        video.play();
        setHaveOne(true);
        setClick(true);
      }
    };

    window.addEventListener('click', handleWindowClick);

    const examplePlane = document.querySelector('#example-plane');

    examplePlane.addEventListener('click', (event) => {
      if (haveOne && haveClick && !firstView && data?.actionUrl !== null) {
        video.pause();
        window.open(data?.actionUrl, '_blank');
      }
    });

    // Cleanup
    return () => {
      exampleTarget.removeEventListener('targetFound', handleTargetFound);
      exampleTarget.removeEventListener('targetLost', handleTargetLost);
      window.removeEventListener('click', handleWindowClick);
    };
    // eslint-disable-next-line
  }, [haveClick, firstView]);

  function take_photo() {
    const myVideo = document.getElementById('Bunny');
    myVideo.pause();
    const video =
      sceneWrapperRef.current.children[
        sceneWrapperRef.current.children.length - 1
      ];
    const canvas = document.createElement('canvas');
    video.pause();

    const style = window.getComputedStyle(video),
      width = parseFloat(style.getPropertyValue('width')),
      height = parseFloat(style.getPropertyValue('height')),
      top = parseFloat(style.getPropertyValue('top')),
      left = parseFloat(style.getPropertyValue('left'));

    const imgLeft = (left * video.videoWidth) / width;
    const imgTop = (top * video.videoHeight) / height;
    const drawLeft = imgLeft > 0 ? 0 : imgLeft;
    const drawTop = imgTop > 0 ? 0 : imgTop;
    const drawWidth = video.videoWidth;
    const drawHeight = video.videoHeight;
    canvas.width = video.videoWidth + imgLeft * 2;
    canvas.height = video.videoHeight + imgTop * 2;

    const imgDataElement = document
      .querySelector('a-scene')
      .components.screenshot.getCanvas('perspective');
    const ctx = canvas.getContext('2d');

    ctx.drawImage(video, drawLeft, drawTop, drawWidth, drawHeight);
    ctx.drawImage(imgDataElement, 0, 0, canvas.width, canvas.height);

    const url = canvas.toDataURL('image/png');
    setPhotoUrl(url);
  }

  useEffect(() => {
    const video = document.getElementById('Bunny');
    if (
      photoUrl === null &&
      haveClick &&
      lastPhotoUrl != null &&
      video !== null
    ) {
      // getMediaURLForTrack(video, data?.filePath);

      console.log('video', video);
      video.play();
    }
    setLastPhotoUrl(photoUrl);
  }, [photoUrl, haveClick, lastPhotoUrl]);

  if (photoUrl !== null)
    return <PhotoScreen url={photoUrl} setUrl={setPhotoUrl} />;
  return (
    <>
      {hasMindArError && <CameraAccessMessage />}
      <div
        ref={sceneWrapperRef}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <a-scene
          ref={sceneRef}
          mindar-image={`imageTargetSrc:${data?.trigger?.filePath};uiScanning: no; filterMinCF:0.0001; filterBeta: 0.001`}
          color-space="sRGB"
          renderer="colorManagement: true,
physicallyCorrectLights"
          vr-mode-ui="enabled: false"
          device-orientation-permission-ui="enabled: false"
          isMobile="true"
          loading-screen="dotsColor: #ffffff; backgroundColor: #2d2d2d"
        >
          <a-assets>
            <img
              id="myimage"
              alt=""
              src={`https://storage.yandexcloud.net/zrenie-dev-bucket/%D0%A2%D0%B5%D1%81%D1%82%D1%8B%20%D0%B4%D0%BB%D1%8F%20%D0%B4%D0%B5%D0%BC%D0%BE/play-button.png`}
            />
            <video
              id="Bunny"
              loop
              playsInline
              crossOrigin="anonymous"
              src={`${data?.filePath}`}
            />
          </a-assets>

          <a-camera
            fov="80"
            position="0 0 0"
            look-controls="enabled: false"
          ></a-camera>

          <a-entity id="example-target" mindar-image-target="targetIndex: 0">
            {haveClick ? (
              <a-video
                id="example-plane"
                shader="chromakey"
                color="0.1 0.9 0.2"
                src="#Bunny"
                position={`${data?.offsetX !== null ? data?.offsetX : 0} ${
                  data?.offsetY !== null ? data?.offsetY : 0
                } ${data?.offsetZ !== null ? data?.offsetZ : 0}`}
                scale={`${data?.scale !== null ? data.scale : 1} ${
                  procent * (data?.scale !== null ? data.scale : 1)
                } 1`}
              />
            ) : (
              <a-plane
                id="example-plane"
                src={'#myimage'}
                position={`0 0 0`}
                scale="1 1 1"
                rotation="0 0 0"
                transparent="true"
              />
            )}
          </a-entity>
        </a-scene>
        <PhotoMode take_photo={take_photo} />
      </div>
    </>
  );
};

export default MindARVideoAlfa;
