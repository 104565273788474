import * as THREE from 'three';
import { useLoader } from '@react-three/fiber';
import React from 'react';
import { Interactive, useXR } from '@react-three/xr';

export function Image(props) {
  const { position, scale, quaternion, path, actionUrl } = props;
  const { session } = useXR();

  const scaleUpd = new THREE.Vector3(scale.x * 10, scale.y * 10, scale.z * 10);
  const texture = useLoader(THREE.TextureLoader, path);

  const w = (texture.image.width / texture.image.height) * 10;
  const h = 10;

  return (
    <Interactive
      onSelect={() => {
        if (actionUrl) {
          session.end();
          setTimeout(() => {
            window.open(actionUrl);
          }, 0);
        }
      }}
    >
      <mesh position={position} scale={scaleUpd} quaternion={quaternion}>
        <planeBufferGeometry attach="geometry" args={[w, h]} />
        <meshLambertMaterial attach="material" map={texture} />
      </mesh>
    </Interactive>
  );
}
