import { useEffect, useState } from 'react';

export const useMindarError = (sceneEl) => {
  const [hasError, setHasError] = useState(sceneEl);

  useEffect(() => {
    if (sceneEl) {
      sceneEl.addEventListener('arError', (event) => {
        setHasError(true);
      });
    }
  }, [sceneEl]);

  return hasError;
};
