import React, { useEffect, useRef, useState } from 'react';
import PhotoMode from './photo-mode';
import { PhotoScreen } from './photo-screen';
import { useMindarError } from './use-mindar-error';
import { CameraAccessMessage } from './camera-access-message';

const MindARImage = ({ data }) => {
  const sceneRef = useRef(null);
  const sceneWrapperRef = useRef(null);
  const [percent, setPercent] = useState(1);
  const [photoUrl, setPhotoUrl] = useState(null);
  const hasMindArError = useMindarError(sceneRef?.current);

  useEffect(() => {
    const sceneEl = sceneRef.current;
    const arSystem = sceneEl.systems['mindar-image-system'];
    sceneEl.addEventListener('renderstart', () => {
      arSystem.start(); // start AR
    });

    const examplePlane = document.querySelector('#example-plane');

    examplePlane.addEventListener('click', (event) => {
      if (data?.actionUrl !== null) {
        window.open(data?.actionUrl, '_blank');
      }
    });

    return () => {
      arSystem.stop();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const img = new Image();
    let height, width;
    img.onload = function () {
      height = img.height;
      width = img.width;
      setPercent(height / width);
    };

    img.src = data?.filePath;
  }, [data]);

  function take_photo() {
    const video =
      sceneWrapperRef.current.children[
        sceneWrapperRef.current.children.length - 1
      ];
    const canvas = document.createElement('canvas');
    video.pause();

    const style = window.getComputedStyle(video),
      width = parseFloat(style.getPropertyValue('width')),
      height = parseFloat(style.getPropertyValue('height')),
      top = parseFloat(style.getPropertyValue('top')),
      left = parseFloat(style.getPropertyValue('left'));

    const imgLeft = (left * video.videoWidth) / width;
    const imgTop = (top * video.videoHeight) / height;
    const drawLeft = imgLeft > 0 ? 0 : imgLeft;
    const drawTop = imgTop > 0 ? 0 : imgTop;
    const drawWidth = video.videoWidth;
    const drawHeight = video.videoHeight;
    canvas.width = video.videoWidth + imgLeft * 2;
    canvas.height = video.videoHeight + imgTop * 2;

    const imgDataElement = document
      .querySelector('a-scene')
      .components.screenshot.getCanvas('perspective');
    const ctx = canvas.getContext('2d');

    ctx.drawImage(video, drawLeft, drawTop, drawWidth, drawHeight);
    ctx.drawImage(imgDataElement, 0, 0, canvas.width, canvas.height);

    const url = canvas.toDataURL('image/png');

    setPhotoUrl(url);
  }

  if (photoUrl !== null)
    return <PhotoScreen url={photoUrl} setUrl={setPhotoUrl} />;
  return (
    <>
      {hasMindArError && <CameraAccessMessage />}
      <div
        ref={sceneWrapperRef}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <a-scene
          ref={sceneRef}
          mindar-image={`imageTargetSrc:${data?.trigger?.filePath}; uiError:no; uiScanning:no; filterMinCF:0.0001; filterBeta: 0.001;sourceWidth:1280; sourceHeight:960; displayWidth: 1280; displayHeight: 960;`}
          color-space="sRGB"
          autoStart="false"
          height="100%"
          width="100%"
          isMobile="true"
          loading-screen="dotsColor: #ffffff; backgroundColor: #2d2d2d"
          vr-mode-ui="enabled: false"
        >
          <a-assets>
            <img id="card" alt="" src={`${data?.filePath}`} />
          </a-assets>

          <a-camera
            fov="80"
            position="0 0 0"
            look-controls="enabled: false"
            height="100%"
            width="100%"
            cursor="fuse: false; rayOrigin: mouse;"
            raycaster="near: 10; far: 10000; objects: .clickable"
          />

          <a-entity id="example-target" mindar-image-target="targetIndex: 0;">
            <a-plane
              id="example-plane"
              class="clickable"
              src="#card"
              width=""
              position={`${data?.offsetX !== null ? data?.offsetX : 0} ${
                data?.offsetY !== null ? data?.offsetY : 0
              } ${data?.offsetZ !== null ? data?.offsetZ : 0}`}
              scale={`${data?.scale !== null ? data.scale : 1} ${
                percent * (data?.scale !== null ? data.scale : 1)
              } 1`}
              rotation="0 0 0"
              transparent="true"
            />
          </a-entity>
        </a-scene>
        <PhotoMode take_photo={take_photo} />
      </div>
    </>
  );
};

export default MindARImage;
