import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import MindARBase from '../components/mindar-base';
import { getData } from '../services/scene';
import { ReactComponent as BottomLeft } from '../assets/icons/bottomLeft.svg';
import { ReactComponent as BottomRight } from '../assets/icons/bottomRight.svg';
import { ReactComponent as TopRight } from '../assets/icons/topRight.svg';
import { ReactComponent as TopLeft } from '../assets/icons/topLeft.svg';
import { ReactComponent as FlashIcon } from '../assets/icons/warning.svg';

import { AlvaSpace } from './alva-space';
import { useMediaQuery } from '../services/use-media-query';
import { SplashScreen } from './splash-screen';
import { useWebxrAvailable } from '../services/use-webxr-available';
import { WebXr } from './web-xr';
import { ErrorBoundary } from 'react-error-boundary';

function Base() {
  const { id } = useParams();
  const { supported } = useWebxrAvailable();
  const [webxrSupported, setWebxrSupported] = useState(supported);
  useEffect(() => {
    setWebxrSupported(supported);
  }, [supported]);

  const [interfaceHidden, setInterfaceHidden] = useState(false);

  const { data } = useQuery({
    queryKey: ['dataItem'],
    enabled: !!id,
    queryFn: () => getData(id),
  });

  const [isLandscape] = useMediaQuery(['(orientation: landscape)']);
  const needPayment = data?.needPayment;

  const isTriggerSpace = data?.data?.trigger?.type?.codeName === 'space';
  const showSpaceMode = isTriggerSpace && !isLandscape && !webxrSupported;
  const showImageMode = !isTriggerSpace && !isLandscape;
  const showWebxr = isTriggerSpace && webxrSupported;

  const handleHideInterface = () => {
    setInterfaceHidden((val) => !val);
  };

  if (needPayment) {
    return (
      <div className="App">
        <div className="container">
          <SplashScreen
            hideButton
            text={
              <>
                Контет недоступен <br />
                Ожидается оплата контент-мейкера
              </>
            }
            icon={<FlashIcon style={{ width: 64, height: 64 }} />}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <div className="container">
        {!interfaceHidden && (
          <>
            <BottomLeft className="base-icon bottom-left-icon" />
            <BottomRight className="base-icon bottom-right-icon" />
            <TopRight className="base-icon top-right-icon" />
            <TopLeft className="base-icon top-left-icon" />
            <img
              className="base-icon label-icon"
              alt=""
              src="/assets/images/logo_label.png"
            />
          </>
        )}

        {showImageMode && <MindARBase data={data?.data} />}
        {showSpaceMode && <AlvaSpace data={data?.data} />}
        {isLandscape && <SplashScreen />}
        {showWebxr && (
          <ErrorBoundary
            onError={() => {
              console.log(' onError ');
              setWebxrSupported(false);
            }}
          >
            <WebXr
              data={data?.data}
              onHideInterface={handleHideInterface}
              interfaceHidden={interfaceHidden}
              onError={() => setWebxrSupported(false)}
            />
          </ErrorBoundary>
        )}
      </div>
    </div>
  );
}

export default Base;
