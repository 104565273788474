import React, { memo } from 'react';
import './splash-screen.css';

export const SplashScreen = memo((props) => {
  const { onHide, text, show, hideButton, icon } = props;

  return (
    <div id="overlay" className={`overlay ${show === false ? 'hide' : ''}`}>
      {!hideButton && (
        <button id="start_button" className="start_button" onClick={onHide}>
          Начать
        </button>
      )}
      {!!icon && icon}
      {!!text && <div className="overlay-text">{text}</div>}
    </div>
  );
});
