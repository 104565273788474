import React, { useState } from 'react';
import '../app.css';
// import { ReactComponent as Camera } from "../assets/icons/camera.svg";
import { ReactComponent as Layers } from '../assets/icons/layers.svg';
import { ReactComponent as CameraBtn } from '../assets/icons/cameraBtn.svg';
// import { PhotoScreen } from './photo-screen';

const PhotoMode = ({ take_photo }) => {
  const [isShow, setIsShow] = useState(true);
  const [activeMode, setActiveMode] = useState(1);

  const showPhotoMode = () => {
    setIsShow((prev) => !prev);
  };

  return (
    <div className="container">
      <Layers className="base-icon camera-icon" onClick={showPhotoMode} />
      <div>
        {isShow && (
          <>
            <CameraBtn
              className="camera-btn"
              id="camera-btn"
              onClick={take_photo}
            />
            <div className="container-change-action">
              <p
                className={`${activeMode === 1 ? '' : 'no-active-mode'}`}
                onClick={() => setActiveMode(1)}
              >
                ФОТО
              </p>
              {/* {getOS() !== "iOS" && (
                <p
                  className={`${activeMode === 2 ? "" : "no-active-mode"}`}
                  onClick={() => setActiveMode(2)}
                >
                  ВИДЕО
                </p>
              )} */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PhotoMode;
