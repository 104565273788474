import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import React from 'react';

export function Model(props) {
  const { position, scale, quaternion, path } = props;

  const scaleUpd = new THREE.Vector3(scale.x * 10, scale.y * 10, scale.z * 10);

  const { animations, scene } = useGLTF(path);

  let mixer = new THREE.AnimationMixer(scene);
  animations.forEach((clip) => {
    const action = mixer.clipAction(clip);
    action.play();
  });
  useFrame((state, delta) => {
    mixer.update(delta);
  });

  // useAnimations(animations);
  /*useEffect(() => {
    // actions[names[0]].reset().fadeIn(0.5).play();
    //  return () => actions[names[0]].fadeOut(0.5).stop();
    console.log(' actions ', actions);
    console.log(' names ', names);
  }, [actions, names]);*/

  return (
    <mesh
      position={position}
      scale={scaleUpd}
      quaternion={quaternion}
      castShadow
      receiveShadow
    >
      <primitive object={scene} />
    </mesh>
  );
}
