import React, { memo, useContext } from 'react';
import './photo-screen.css';
import { ReactComponent as Save } from '../../assets/icons/save.svg';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import { GlobalContext } from '../../app';

export const PhotoScreen = memo((props) => {
  const { url, setUrl } = props;
  const { reloadApp } = useContext(GlobalContext);

  const closeScreen = () => {
    setUrl(null);
    reloadApp();
  };

  const savePhoto = () => {
    const link = document.createElement('a');
    link.download = 'screen';
    link.href = url;
    link.click();
  };

  return (
    <div className="overlay-photo">
     
      <Cross className="close-button" onClick={closeScreen} />
      <Save className="save-icon" onClick={savePhoto} />
      <img src={url} className="photo" alt="Ваше фото" />
    </div>
  );
});
